<template>
  <div class="app-box">
    <div v-if="xhrData" class="data-tip">数据更新时间为{{xhrData.updateTime}}，最终结果以风险运营部数据为准。</div>
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month" @change="screenChange"></x-screen-tab>
    <div v-if="xhrData" class="count-title-box">总计(元)：{{xhrData.totalAmount|formatNum}}</div>
    <x-scroll-view :top="1.29">
      <table v-if="xhrData&&xhrData.items.length>0" class="data-table double-table">
        <colgroup>
          <col width="30%">
          <col width="20%">
          <col width="20%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>货品简称</span>
            </th>
            <th>
              <span>单位</span>
            </th>
            <th>
              <span>回款数量</span>
            </th>
            <th scope="col">
              <span>回款金额(元)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.items" :key="index" @click="rowEvent(item.goodsAbbreviatedName);">
            <th scope="row">{{item.goodsAbbreviatedName}}</th>
            <td>{{item.unit}}</td>
            <td>{{item.backCount}}</td>
            <td>{{item.backAmount|formatNum}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.items.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import noDataBg from "@/components/noDataBg";
import { cachePage } from "@/script/mixins/cachePage";
import { countInstrumentSalesFlow } from "@/api/reportCenter";
import { formatNum } from "@/script/tools";

export default {
  name: "apparatusFlowIndex",
  data () {
    return {
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
    };
  },
  mixins: [cachePage, formatNum],
  created () {
    document.title = "器械终端流向";
    this.getDataList();
  },
  methods: {
    getDataList () {
      countInstrumentSalesFlow(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    },
    rowEvent (goodsAbbreviatedName) {
      this.$router.push({
        path: `/reportCenter/apparatusFlow/twoLevel/${this.selectObject.year}/${this.selectObject.month || 0}/${goodsAbbreviatedName}`
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "器械终端流向";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
